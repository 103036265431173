import React from 'react'
import * as PropTypes from 'prop-types'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

const propTypes = {
  data: PropTypes.object.isRequired,
}

class ThankYou extends React.Component {
  render() {
    return (
      <Layout data={this.props.data} location={this.props.location}>
        <Helmet>
          <title>Thank You - Forbes Netherlands</title>
        </Helmet>
        <div className="w-full h-screen relative">
          <div className="mt-56 absolute w-full">
            <div className="mx-auto w-11/12 text-center">
              <h1
                className="text-3xl font-medium tracking-tighter"
                style={{ fontFamily: `Work Sans` }}
              >
                THANK YOU!
              </h1>
              <p
                className="mt-10 w-full md:w-1/2 mx-auto font-medium"
                style={{ fontFamily: `Work Sans`, fontSize: `19px` }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
                tristique nibh sollicitudin, ultricies erat in, placerat lacus.
                Fusce rutrum eros eu dignissim feugiat. Cras ligula nibh,
                suscipit in enim id, imperdiet bibendum metus. Donec diam augue,
                malesuada non vestibulum in, imperdiet sed ante. Donec
                condimentum mi leo, vitae efficitur dolor faucibus id.
              </p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

ThankYou.propTypes = propTypes

export default ThankYou

export const pageQuery = graphql`
  query ThankYouENQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`
